// members.js
// two methods hit API: getMembers() and getMember()

import { 
  networkError,
  responseError
} from './';

const token = localStorage.getItem('token');

export function getMembers_original() { // _original
  return fetch('https://data.physmodo.net/api/v202203/user/', {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  })
  .then(data => data.json())
  .then(data => makeMembersFromUsers(data))
  // .then(data => console.log(data)) // {data: Array(12), pagination{...}, statistics: {...}}
}

export function getMembers(){ // _synchronous1CheckRespError fetches list of members synchronously, checking for http errors
  console.log('~~~~~ members service - getMembers_synchronous1CheckRespError()');
  return fetch('https://data.physmodo.net/api/v202203/user/', {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  })
  .then(resp => {
    console.log('getMembers_synchronous1CheckRespError() - resp is:');
    console.log(resp);
    if (resp.ok === true){
      // alert('getMembers() - response.ok === true');
      console.log('getMembers() - response.ok === true');
      localStorage.setItem('errorToast', null);
      return resp;
    }
    else
    {
      //alert('getMembers() - response.ok !== true, response.status is ' + resp.status);
      console.log('getMembers() - response.ok !== true, response.status is ' + resp.status);
      responseError('getMembers', resp);
      return resp;
    }
  })
  .then(data => data.json())
  .then(data => makeMembersFromUsers(data))
}

// used in History screen and Assessment Details screen(for Member name or email) 
export function getMember(memberid) {
  return fetch('https://data.physmodo.net/api/v202203/user/', {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  })
  .then(resp => {
    console.log('getMember() - resp is:');
    console.log(resp);
    if (resp.ok === true){
      // alert('getMembers() - response.ok === true');
      console.log('getMember() - response.ok === true');
      localStorage.setItem('errorToast', null);
      return resp;
    }
    else
    {
      //alert('getMembers() - response.ok !== true, response.status is ' + resp.status);
      console.log('getMember() - response.ok !== true, response.status is ' + resp.status);
      responseError('getMember', resp);
      return resp;
    }
  })
  .then(data => data.json())
  .then(data => getMemberFromUsersArray(memberid, data)) // maybe not how we would want to do it?
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max) + 1;
}

function randomDate(start, end) {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime())).toLocaleString();
}

// function makeOneMemberFromOneUser(respJson) {
//   // eventually might need this if/when we have a route that returns a single user object?
// }

function getMemberFromUsersArray(id_guid, respJson) {

  // console.log("~~~~~ getMemberFromUsersArray() - id_guid: " + id_guid);
  // console.log("~~~~~ getMemberFromUsersArray() - respJson.data[0].email_string: " + respJson.data[0].email_string);

  const randomAssessmentDate = randomDate(new Date(2018, 0, 1), new Date());
  const monthsSinceJoined = getRandomInt(24);

  const fakeMember = {
    id_guid: "unknown_user",
    email: "fake_user@websmith.us",
    lastAssessmentDate: randomAssessmentDate,
    AssessmentsCountThisMonth: 15,
    AssessmentsCountAllTime: 117
  };

  if (!id_guid || 
    id_guid.length < 32 || 
    !respJson || 
    !respJson.data ||
    !Array.isArray(respJson.data)) {
    // console.log("~~~~~ getMemberFromUsersArray() - bad data, returning fakeMember"); 
    return fakeMember;
  }

  const usersArray = respJson.data;

  // console.log("~~~~~ getMemberFromUsersArray() - usersArray: " + usersArray);

  const userObjectsMatching = usersArray.filter((user) => {
    return user.id_guid === id_guid;
  });

  // console.log("~~~~~ getMemberFromUsersArray() - userObjectsMatching: " + userObjectsMatching);
  
  if (userObjectsMatching.length === 0) {
    return fakeMember;
  }

  const userObject = userObjectsMatching[0];

  // console.log("~~~~~ getMemberFromUsersArray() - userObject.email_string: " + userObject.email_string);

  let thisMember = {
    id_guid: "unknown_user",
    email: "unknown_email",
    lastAssessmentDate: randomDate(new Date(2018, 0, 1), new Date()),
    AssessmentsCountThisMonth: getRandomInt(14),
    AssessmentsCountAllTime: getRandomInt(monthsSinceJoined * getRandomInt(14))
  };

  for (const [key, value] of Object.entries(userObject)) {
    // if (key === "id_guid" || key === "email_string") {
    //   if (key === "id_guid") {
    //     thisMember.id_guid = value;
    //   } else if (key === "email_string") {
    //     thisMember.email = value;
    //   } 
    // }

    switch (key) {
      case "id_guid":
        thisMember.id_guid = value;
        break;
      case "email_string":
        thisMember.email = value;
        break;
      case "assessment_last_datetime":
        thisMember.lastAssessmentDate = new Date(value).toLocaleString(); // not really used here, at present
        break;
      case "assessment_month_count_integer":
        thisMember.AssessmentsCountThisMonth = value; // not really used here, at present
        break;
      case "assessment_total_count_integer":
        thisMember.AssessmentsCountAllTime = value; // not really used here, at present
        break;
      default:
        // do nothing
    }
  };

  return thisMember;
}

const membersWithoutAssessments = [
'bones@physmodo.com',
'orange.robot@physmodo.com',
'joseph@physmodo.com',
'mail@stevenray.com',
'toccata6767@yahoo.com',
'steve@thebashapp.com',
'jgoodrick@physmodo.com',
'support@physmodo.com',
'nathanreyes.me@gmail.com',
'tester003@gmail.com',
'robyn@robynmenter.com',
'physmodo@physmodo.com',
'sgoodrickjr@gmail.com',
'cred-test@physmodo.com',
'cred-test2@physmodo.com',
'trainfasst@gmail.com',
'tester006@physmodo.com',
'tester004@physmodo.com',
'test002@physmodo.com',
'dannyjeee@yahoo.com',
'mike3@physmodo.com',
'mike4',
'mike4@physmodo.com',
'mike5@physmodo.com',
'mike6@physmodo.com',
'mike7@physmodo.com',
'mike8@physmodo.com',
'mike9@physmodo.com',
'bob7@physmodo.com',
'bob2@physmodo.com',
'adlsjkfa;lsdj@;alksdjf;al.com',
'example1234@some.client',
'asdfasdfas@kjhsdkfj.lkjsd',
'chantellegroenewoud@gmail.com',
'joseph@pearsports.com',
'weegesantoro@physmodo.com'
]

// will parse users to generate members array
function makeMembersFromUsers(respJson) { // {data: Array(12), pagination{...}, statistics: {...}}

  console.log("~~~~~ makeMembersFromUsers() - respJson = ");
  console.log(respJson);

  const randomAssessmentDate = randomDate(new Date(2018, 0, 1), new Date());
  
  let fakeMember = {
    id_guid: "unknown_user",
    email: "fake_user@websmith.us",
    lastAssessmentDate: randomAssessmentDate,
    AssessmentsCountThisMonth: 15,
    AssessmentsCountAllTime: 117
  }
  
  let memberStats = { };

  let allMembersArray = [fakeMember];

  if (respJson && respJson.data && Array.isArray(respJson.data) && respJson.data.length > 0) {
    
    allMembersArray.pop(); // there are members, remove the fake user record

    respJson.data.forEach((user) => {
      const monthsSinceJoined = getRandomInt(24);

      let thisMember = {
        id_guid: "unknown_user",
        email: "unknown_email",
        lastAssessmentDate: randomDate(new Date(2018, 0, 1), new Date()),
        AssessmentsCountThisMonth: getRandomInt(14),
        AssessmentsCountAllTime: getRandomInt(monthsSinceJoined * getRandomInt(14))
      };

      for (const [key, value] of Object.entries(user)) {
        // console.log("~~~~~ makeMembersFromUsers() - key = " + key);
        if (key === "id_guid" || key === "user_id_guid") {
          // console.log("~~~~~ makeMembersFromUsers() - id_guid = " + value);
          thisMember.id_guid = value;
        } else if (key === "email_string") {
          // console.log("~~~~~ makeMembersFromUsers() - email_string = " + value);
          thisMember.email = value;
        } else if (key === "assessment_last_datetime" || key === "assessment_last_datatime") {
          // console.log("~~~~~ makeMembersFromUsers() - assessment_last_datetime = " + value);
          thisMember.lastAssessmentDate = new Date(value).toLocaleString();
        } else if (key === "assessment_total_count_integer") {
          // console.log("~~~~~ makeMembersFromUsers() - assessment_total_count_integer = " + value);
          thisMember.AssessmentsCountAllTime = value;
        } else if (key === "assessment_month_count_integer") {
          // console.log("~~~~~ makeMembersFromUsers() - assessment_month_count_integer = " + value);
          thisMember.AssessmentsCountThisMonth = value;
        }
      };

      // console.log("~~~~~ makeMembersFromUsers() - thisMember.lastAssessmentDate: " + thisMember.lastAssessmentDate);

      if (!membersWithoutAssessments.includes(thisMember.email)) {
        allMembersArray.push(thisMember);

        if (thisMember.email.includes("stretchlab")) {
          console.log("~~~~~ " + thisMember.email);
        }
      }
    });

    console.log("~~~~~ makeMembersFromUsers() - respJson.statistics: " + respJson.statistics);
    console.log("~~~~~ makeMembersFromUsers() - respJson.data: " + respJson.data);
    console.log(respJson.data);
    console.log(allMembersArray);
    
    memberStats = {
      totalUsers: respJson.pagination && respJson.pagination.rows_total ?
       respJson.pagination.rows_total : 777,
      activeUsers: respJson.statistics && respJson.statistics.count_active_integer ?
       respJson.statistics.count_active_integer : 77,
      newUsers: respJson.statistics && respJson.statistics.count_this_week_integer ?
       respJson.statistics.count_this_week_integer :7,
      thisWeekScans: 84,  // TODO: move to KioskStats?
      lastWeekScans: 81   // TODO: move to KioskStats?
    }
  } 

  const outputObject = {
    members: allMembersArray,
    memberStats: memberStats
  }

  console.log(outputObject);
    
  // return {
  //   members: allMembersArray,
  //   memberStats: memberStats
  // }
  return outputObject;
}
