// constants/index.js

import { accentColor, colors, logoSrc, loadingSrc } from './themes_and_colors';

const localStorageUpdateTimeout = 1500; // milliseconds to wait after checkUserAuth() runs before checking stored token

export {
  localStorageUpdateTimeout,
  accentColor,
  logoSrc,
  loadingSrc,
  colors
}