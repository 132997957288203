// Overview.js

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css'
import { getKioskStats, getMembers } from '../services';
import { localStorageUpdateTimeout } from '../constants';
import { Title, EZSpacer, ContentWrapper } from './styled/title';
import LoadingDivLayer  from './styled/LoadingDivLayer';
import BarGraph from './styled/BarGraph';
import BigStat from './styled/BigStat';
import MembersTable from './styled/MembersTable';

function sortMembersByScanCount(membersArray) {
  membersArray.sort(function(a,b){
    return b.AssessmentsCountAllTime - a.AssessmentsCountAllTime;
  });

  return membersArray;
}


function Overview() {
  const [members, setMembers] = useState([]);
  const [memberStats, setMemberStats] = useState([]);
  const [stats, setKioskStats] = useState([]);
  const [loaded, setLoaded ] = useState(false);
  const [membersFetched, setMembersFetched ] = useState(false);
  const [statsFetched, setStatsFetched ] = useState(false);
  // const [badToken, setBadToken] = useState(false);
  const mounted = useRef(true);
  const navigate = useNavigate();

/*
  It looks like you wrote useEffect(async () => ...) or returned a Promise. Instead, write the async function inside your effect and call it immediately:

  useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await MyAPI.getData(someId);
      // ...
    }
    fetchData();
  }, [someId]); // Or [] if effect doesn't need props or state
*/

    // // declare the async data fetching function
    // const getMembersAsync = async () => {
    //   // get the data from the api
    //   const data = await getMembers();
    //   // convert the data to json
    //   // const json = await response.json();

    //   // set state with the result
    //   // if(mounted.current) {
    //     // let items = membersResp.members;
    //     console.log('useEffect(), getMemberAsync() - data is ');
    //     console.log(data);
    //     let items = data.members;
    //     console.log('useEffect(), getMemberAsync() - items is ' + items);
    //     setMembers(items);
    //     // setMemberStats(membersResp.memberStats);
    //     setMemberStats(data.memberStats);
    //     // setLoaded(true); // arbitrary choice of this api call as longest
    //   // }
    // }

    // // call the function
    // getMembersAsync()
    //   // make sure to catch any error
    //   .catch(console.error);

    //// const getMembersResp = await getMembers();

  useEffect(() => { 

    mounted.current = true; // TODO: how is this useful?
    if(members.length > 1) { // TODO: exits this routine only if State members array is not empty !!! (infinite loop if no response, or mt)
    // if(loaded) { 
    // if(membersFetched){
      return;
    }
    getMembers()
    .then(membersResp => {
      const response = membersResp;
      setMembersFetched(true);
      console.log('Overview(), useEffect() - response from getMembers(), token in localStorage is ' + localStorage.getItem("token"));
      console.log('useEffect() - response from getMembers() is ' + response);
      console.log(response);
      console.log('useEffect() - membersResp from getMembers() is ' + membersResp);
      console.log(membersResp);
      setTimeout(() => {
        if(localStorage.getItem('token') === null || localStorage.getItem('token').length < 40){
          window.location.reload();
        }
      }, localStorageUpdateTimeout);
      if(mounted.current) {
        // let items = membersResp.members;
        let items = response.members;
        setMembers(items);
        // setMemberStats(membersResp.memberStats);
        setMemberStats(response.memberStats);
        // setMembersFetched(true);
        // setLoaded(true); // arbitrary choice of this api call as longest

      }
    })

    // const getMembersResp = await getMembers();
    // console.log('useEffect() - response from getMembers() is ' + getMembersResp);
    // console.log(getMembersResp);
    // if(mounted.current) {
    //   setMembers(getMembersResp.members);
    //   setMemberStats(getMembersResp.memberStats);
    //   // setLoaded(true); // arbitrary choice of this api call as longest
    // }

  }, [members])


  useEffect(() => {
    if(stats.thisWeekAssessmentsCount) {
    // if(statsFetched){
      return;
    }
    getKioskStats()
    .then(item => {
      setStatsFetched(true);
      console.log('Overview(), useEffect() - response from getKioskStats(), token in localStorage is ' + localStorage.getItem("token"));
      if(mounted.current) { // TODO: how is this useful?
        setKioskStats(item);
        // setLoaded(true); // arbitrary choice of this api call as longest
        // setStatsFetched(true);
        setTimeout(() => {
          if(localStorage.getItem('token') === null || localStorage.getItem('token').length < 40){
            window.location.reload();
          }
        }, localStorageUpdateTimeout);
      }
    })
  }, [stats])

  function handleMemberClick(memberid) {
    navigate('/history?from=Overview&memberid=' + memberid);
  }
  
  return (
    <div>
      {(membersFetched && statsFetched) ? '' : <LoadingDivLayer />}
      <ContentWrapper className="wrapper" loaded={membersFetched && statsFetched}>
        {/* <EZSpacer height={"13vh"} /> */}
        <BigStat 
          value={memberStats.totalUsers} 
          title='Members' 
          subValue={memberStats.newUsers > 0 ? '+' + memberStats.newUsers : '-' + memberStats.newUsers}
          subtitle='since last week'>
        </BigStat>
        <BigStat 
          value={stats.thisWeekAssessmentsCount} 
          title='Assessments This Week' 
          subValue={stats.lastWeekAssessmentsCount}
          subtitle='by this day last week'>
        </BigStat>
        <EZSpacer height={"3vw"} />
        <Title>Assessments taken over the past 30 Days</Title>
        <BarGraph data={stats.last30daysAssessmentCounts} />
        <EZSpacer height={"6vw"} />
        <Title>Top Members</Title>
        <EZSpacer height={"2vw"} />
        <MembersTable members={sortMembersByScanCount(members)} handleClick={handleMemberClick} />
      </ContentWrapper>
    </div>
  );
}

export default Overview;