import React from 'react';
import { useState } from "react";
// import { propTypes } from 'react-bootstrap/esm/Image';

import styled from 'styled-components'
import { accentColor } from '../../constants';

const lightGreyText = "#b4b4b4";
const tealText = accentColor;

const SortableTableRow = styled.tr`
  cursor: pointer;
  padding-top: 2vw;
  padding-bottom: 2vw;
  border-bottom: 1px solid #f0f0f0;
  &:hover { background: ghostwhite };
`

const TableHead = ({ columns, handleSorting }) => {
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");

  const handleSortingChange = (accessor) => {
    const sortOrder =
    accessor === sortField && order === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setOrder(sortOrder);
    handleSorting(accessor, sortOrder);
  };

  return (
   <thead>
    <tr style={{ borderBottom: "1px solid #e9e9e9", whiteSpace: "nowrap" }}>
     {columns.map(({ label, accessor, sortable }) => {
      return (
        <th 
          key={accessor}
          onClick={sortable ? () => handleSortingChange(accessor) : null}
          style={{ fontSize: "1vw", color: "#b4b4b4", fontWeight: "400", paddingRight: "2.4vw", paddingLeft: "2.4vw" }}
        >
          {label}
        </th>
      );
     })}
    </tr>
   </thead>
  );
 };

 const TableBody = ({ tableData, columns, onRowClick }) => {
  return (
   <tbody>
    {tableData.map((data) => {
     return (
      <SortableTableRow key={data.id_guid}  onClick={function(){onRowClick(data.id_guid)}}>
       {columns.map(({ accessor, align, color }) => {
        const tData = data[accessor] ? data[accessor] : "——";
        return <td key={accessor} align={align} style={{ color: color, fontSize: "1.1vw", padding: "1.2vw" }}>{tData}</td>;
       })}
      </SortableTableRow>
     );
    })}
   </tbody>
  );
 };

function MembersTable(props) {
  // const {
  //   members,
  //   handleClick
  // } = props;

  const [tableData, setTableData] = useState(props.members);

  // console.log('~~~~~ props.members.length: ' + props.members.length);

  const columns = [
    { label: "Member", accessor: "email", align: "left", color: tealText, sortable: true },
    { label: "Last Assessment", accessor: "lastAssessmentDate", align: "center", color: lightGreyText, sortable: true },
    { label: "Assessments this Month", accessor: "AssessmentsCountThisMonth", align: "center", color: lightGreyText, sortable: true },
    { label: "Assessments All Time", accessor: "AssessmentsCountAllTime", align: "center", color: lightGreyText, sortable: true },
  ];

   const handleSorting = (sortField, sortOrder) => {
    setTableData(props.members);
    console.log('~~~~~ handleSorting() - tableData.length: ' + tableData.length);
    
    if (sortField) {
      const sorted = [...tableData].sort((a, b) => {
       return (
        a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
         numeric: true,
        }) * (sortOrder === "asc" ? 1 : -1)
       );
      });
      setTableData(sorted);
     }
   };

  return (
    <>
      <table cellPadding={10}>
        <TableHead {...{ columns, handleSorting }} />
        <TableBody columns={columns} tableData={props.members} onRowClick={props.handleClick} />
      </table>
    </>
  );
}

export default MembersTable;
