// assessments.js
// 2 methods hit API: getMemberAssessments(), getAssessmentPageData()

// import { format } from 'date-fns';
// import { assessmentsArrayMock, assessmentMock } from './mocks'

import { 
  networkError,
  responseError
} from './';



const token = localStorage.getItem('token');
const isStretchLab = localStorage.getItem('stretchlab') === 'true' ? true : false;
const maxIssueScore = 89; // scores above 89 are not problems?

/*
// used in Member History page
export function getMemberAssessments(memberid) {
  return fetch('https://data.physmodo.net/api/v202203/user/' + memberid + '/assessment', {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }})  
    .then(data => data.json())
    .then(data => makeAssessmentsMetaData(data))
}
*/

// used in Member History page
export function getMemberAssessments(memberid) {
  return fetch('https://data.physmodo.net/api/v202203/user/' + memberid + '/assessment', {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  })
  .then(resp => {
    if (resp.ok === true){
      // alert('getMemberAssessments() - response.ok === true');
      console.log('getMemberAssessments() - response.ok === true');
      localStorage.setItem('errorToast', null);
      return resp;
    }
    else
    {
      //alert('getMemberAssessments() - response.ok !== true, response.status is ' + resp.status);
      console.log('getMemberAssessments() - response.ok !== true, response.status is ' + resp.status);
      responseError('getMemberAssessments', resp);
      return resp;
    }
  })  
  .then(data => data.json())
  .then(data => makeAssessmentsMetaData(data))
}


/*
export function getMembers(){ // _synchronous1CheckRespError fetches list of members synchronously, checking for http errors
  console.log('~~~~~ members service - getMembers_synchronous1CheckRespError()');
  return fetch('https://data.physmodo.net/api/v202203/user/', {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  })
  .then(resp => {
    console.log('getMembers_synchronous1CheckRespError() - resp is:');
    console.log(resp);
    if (resp.ok === true){
      // alert('getMembers() - response.ok === true');
      console.log('getMembers() - response.ok === true');
      localStorage.setItem('errorToast', null);
      return resp;
    }
    else
    {
      //alert('getMembers() - response.ok !== true, response.status is ' + resp.status);
      console.log('getMembers() - response.ok !== true, response.status is ' + resp.status);
      responseError('getMembers', resp);
      return resp;
    }
    
  })
  .then(data => data.json())
  .then(data => makeMembersFromUsers(data))
}
*/




// function getAssessmentData(id_guid) {
//   return fetch('https://data.physmodo.net/api/v202203/assessment/' + id_guid, {
//     method: 'GET',
//     mode: 'cors',
//     headers: {
//       'Content-Type': 'application/json',
//       'Authorization': token
//     }})  
//     .then(data => data.json())
// }

// function testFunction() {
//   return fetch('http://34.245.86.200:9084/api/auth/login', {
//     method:'POST',
//     body: JSON.stringify({Identifier:'17123456788', Password:'bambam'}),
//     headers: {
//       'Content-type':'application/json'
//     }})
//     .then(response => { return response.json()})
//     .then(
//       data => { 
//         const header ={'Authorization': 'Bearer '+data.token}
  
//         return fetch('http://34.245.86.200:9085/api/user', {
//             method :'GET',
//             headers: header
//         })
//       }).then(msg =>{
//    console.log(msg.body)
//   })
// }



// used in Assessment Details page

export function getAssessmentPageData(assessmentId, priorAssessmentId) {
  let assessmentJson;
  // let priorAssessmentJson;
  return fetch('https://data.physmodo.net/api/v202203/assessment/' + assessmentId, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token
    }
  })
  .then(resp => {
    console.log('getMember() - resp is:');
    console.log(resp);
    if (resp.ok === true){
      // alert('getMembers() - response.ok === true');
      console.log('getMember() - response.ok === true');
      localStorage.setItem('errorToast', null);
      return resp;
    }
    else
    {
      //alert('getMembers() - response.ok !== true, response.status is ' + resp.status);
      console.log('getMember() - response.ok !== true, response.status is ' + resp.status);
      responseError('getMember', resp);
      return resp;
    }
  })  
  .then(data => data.json())
  .then(data => {
    assessmentJson = data 
    return fetch('https://data.physmodo.net/api/v202203/assessment/' + priorAssessmentId, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
       }})
      .then(data => data.json())
      .then(data => makeIssuesAndScoresWithTrends(assessmentJson, data))
    })

  // let priorAssessmentJson = getAssessmentData(priorAssessmentId);
  // return makeIssuesAndScoresWithTrends(assessmentJson, priorAssessmentJson);
    // .then(data => makeIssuesAndScores(data)) // scoresData, issuesData & metaData 
}


function makeMetaDataFrom(assessment) { // return { dateTime, memberid, assessmentid }
  let thisAssessment = assessment;
  
  if (Array.isArray(assessment)) {
    thisAssessment = assessment[0];
  }

  let metaDataObj = {};
  
  let assessmentID = thisAssessment.id_guid ? 
    thisAssessment.id_guid : "unknown_assessment_id" ;

  let assessmentMemberID = thisAssessment.athlete_user_id_guid ?
    thisAssessment.athlete_user_id_guid : "unknown_member_id";

  let performedDatetime = thisAssessment.performed_datetime ?
    // thisAssessment.performed_datetime : "12-12-12T12:12:12.121Z";
    // new Date(thisAssessment.performed_datetime).toLocaleDateString() + " " + new Date(thisAssessment.performed_datetime).toLocaleTimeString() : "12-12-12T12:12:12.121Z";
    new Date(thisAssessment.performed_datetime).toLocaleString() : "12-12-12T12:12:12.121Z";
  
  // console.log("~~~~~ makeMetaDataFrom() - assessment_id " + assessmentID + ", performed_datetime: " + thisAssessment.performed_datetime + ", after localization: " + performedDatetime);
  // let attributeHash = thisAssessment.attribute_hash ?
  //   thisAssessment.attribute_hash : null;

  // let iosDatetime = (null)
  // let allScoresDatetime = "unknown_datetime";

  // if (attributeHash) {
  //   iosDatetime = attributeHash.ios_datetime ?
  //   attributeHash.ios_datetime
  //   : (null);

  //   if (iosDatetime) {
  //     // allScoresDatetime = iosDatetime.created_datetime;
  //     allScoresDatetime = iosDatetime.value_string;
  //   }
  // }

  // metaDataObj.memberid = "cf0cca90-a418-4248-9ad6-0d732d761407";
  // metaDataObj.memberid = thisAssessment.athlete_user_id_guid;
  metaDataObj.memberid = assessmentMemberID;
  metaDataObj.assessmentid = assessmentID;
  metaDataObj.datetime = performedDatetime;

  return metaDataObj;
}

function score100(fullScoreObject) { // make score 100-based if it is 1-based
  const rawScoreString = fullScoreObject.value_string;
  const stringScore100 = parseFloat(rawScoreString) < 1.0 ?
    Math.floor(parseFloat(rawScoreString) * 100).toString() :
    rawScoreString;
  return stringScore100;
}

function nameDerived(scoreObjectNameString) { // get order, title & subtitle?
  // console.log("~~~~~ nameDerived() - scoreObjectNameString is " + scoreObjectNameString);
  
  let nameDerived;
  const domain = scoreObjectNameString.split('_')[1];

  switch (scoreObjectNameString) {
    case "score_composite":
      nameDerived = {       
        domain: "",
        title: "Composite",
        subtitle: "",
        order: 1
      };
      break;
    case "score_mobility":
      nameDerived = {       
        domain: "",
        title: "Mobility",
        subtitle: "",
        order: 2
      };
      break;
    case "score_stability":
      nameDerived = {       
        domain: "",
        title: isStretchLab ? "Activation" : "Stability",
        subtitle: "",
        order: 3
      };
      break;
    case "score_posture":
      nameDerived = {       
        domain: "",
        title: "Posture",
        subtitle: "",
        order: 4
      };
      break;
    case "score_symmetry":
      nameDerived = {       
        domain: "",
        title: "Symmetry",
        subtitle: "",
        order: 5
      };
      break;
    case "score_mobility_elbow_left":
      nameDerived = {       
        domain: "mobility",
        title: "Elbow Left",
        subtitle: "Elbow words",
        order: 6
      };
      break;
    case "score_mobility_elbow_right":
      nameDerived = { 
        domain: "mobility",      
        title: "Elbow Right",
        subtitle: "Elbow words",
        order: 7
      };
      break;
    case "score_mobility_shoulder_left":
      nameDerived = {       
        domain: "mobility",
        title: "Shoulder Left",
        subtitle: "Shoulder words",
        order: 8
      };
      break;
    case "score_mobility_shoulder_right":
      nameDerived = { 
        domain: "mobility",      
        title: "Shoulder Right",
        subtitle: "Shoulder words",
        order: 9
      };
      break;
    case "score_mobility_hip_left":
      nameDerived = {     
        domain: "mobility",  
        title: "Hip Left",
        subtitle: "Hip words",
        order: 10
      };
      break;
    case "score_mobility_hip_right":
      nameDerived = {     
        domain: "mobility",  
        title: "Hip Right",
        subtitle: "Hip words",
        order: 11
      };
      break;  
    case "score_mobility_femur_left":
      nameDerived = {      
        domain: "mobility", 
        // title: "Femur Left",
        // subtitle: "Femur words",
        // title: isStretchLab ? "Femur Left" : "Squat Depth Left",
        // subtitle: isStretchLab ? "Femur words" : "Squat Depth words",
        title: "Squat Depth Left",
        subtitle: "Squat Depth words",
        order: 12
      };
      break; 
    case "score_mobility_femur_right":
      nameDerived = {     
        domain: "mobility",  
        // title: "Femur Right",
        // subtitle: "Femur words",
        // title: isStretchLab ? "Femur Right" : "Squat Depth Right",
        // subtitle: isStretchLab ? "Femur words" : "Squat Depth words",
        title: "Squat Depth Right",
        subtitle: "Squat Depth words",
        order: 13
      };
      break;
    case "score_mobility_knee_left":
      nameDerived = {      
        domain: "mobility", 
        title: "Knee Left",
        subtitle: "Knee words",
        order: 14
      };
      break;
    case "score_mobility_knee_right":
      nameDerived = {      
        domain: "mobility", 
        title: "Knee Right",
        subtitle: "Knee words",
        order: 15
      };
      break;
    case "score_mobility_tibia_left":
      nameDerived = {      
        domain: "mobility", 
        // title: isStretchLab ? "Tibia Left" : "Ankle Left",
        // subtitle: isStretchLab ? "Tibia words" : "Ankle words",
        title: "Ankle Left",
        subtitle: "Ankle words",
        order: 16
      };
      break;
    case "score_mobility_tibia_right":
      nameDerived = {       
        domain: "mobility",
        // title: isStretchLab ? "Tibia Right" : "Ankle Right",
        // subtitle: isStretchLab ? "Tibia words" : "Ankle words",
        title: "Ankle Right",
        subtitle: "Ankle words",
        order: 17
      };
      break;
    case "score_stability_scap_left":
      nameDerived = {       
        domain: "stability",
        // title: "Scap Left",
        // subtitle: "Scap words",
        title: "Shoulder Left",
        subtitle: "Shoulder words",
        order: 18
      };
      break;
    case "score_stability_scap_right":
      nameDerived = {       
        domain: "stability",
        // title: "Scap Right",
        // subtitle: "Scap words",
        title: "Shoulder Right",
        subtitle: "Shoulder words",
        order: 19
      };
      break;
    case "score_stability_tibia_left_xy":
      nameDerived = {     
        domain: "stability",  
        title: "Valgus/Varus Left",
        subtitle: "Valgus/Varus words",
        order: 20
      };
      break;
    case "score_stability_tibia_left_x_y":
      nameDerived = {       
        domain: "stability",
        title: "Valgus/Varus Left",
        subtitle: "Valgus/Varus words",
        order: 20
      };
      break;
    case "score_stability_tibia_right_xy":
      nameDerived = {       
        domain: "stability",
        title: "Valgus/Varus Right",
        subtitle: "Valgus/Varus words",
        order: 19
      };
      break;
    case "score_stability_tibia_right_x_y":
      nameDerived = {   
        domain: "stability",    
        title: "Valgus/Varus Right",
        subtitle: "Valgus/Varus words",
        order: 21
      };
      break;
    case "score_stability_spine_center_yz":
      nameDerived = {      
        domain: "stability", 
        title: "Spine Angle",
        subtitle: "Spine Angle words",
        order: 22
      };
      break;
    case "score_stability_spine_center_y_z":
      nameDerived = {       
        domain: "stability",
        title: "Spine Angle",
        subtitle: "Spine Angle words",
        order: 22
      };
      break;
    case "score_posture_shoulder_level":
      nameDerived = {     
        domain: "posture",  
        title: "Shoulder Level",
        subtitle: "Shoulder Level words",
        order: 23
      };
      break;
    case "score_posture_spine_center_xy":
      nameDerived = {   
        domain: "posture",    
        // title: "Center of Mass",
        // subtitle:"Center of Mass words",
        // title: isStretchLab ? "Center of Mass" : "Spine Center",
        // subtitle: isStretchLab ? "Center of Mass words" : "Spine Center words",
        title: "Spine Center",
        subtitle:"Spine Center words",
        order: 24
      };
      break;
    case "score_posture_spine_center_x_y":
      nameDerived = {   
        domain: "posture", 
        // title: "Center of Mass",
        // subtitle:"Center of Mass words",   
        // title: isStretchLab ? "Center of Mass" : "Spine Center",
        // subtitle: isStretchLab ? "Center of Mass words" : "Spine Center words",
        title: "Spine Center",
        subtitle:"Spine Center words",
        order: 24
      };
      break;
    case "score_posture_spine_center_yz":
      nameDerived = {   
        domain: "posture",    
        title: "Spine Center YZ",
        subtitle: "Spine Center words",
        order: 25
      };
      break;
    case "score_posture_spine_center_y_z":
      nameDerived = {   
        domain: "posture",    
        title: "Spine Center YZ",
        subtitle: "Spine Center words",
        order: 25
      };
      break;
    case "score_symmetry_elbow":
      nameDerived = {       
        domain: "symmetry",
        title: "Elbow",
        subtitle: "Elbow words",
        order: 26
      };
      break;
    case "score_symmetry_shoulder":
      nameDerived = {       
        domain: "symmetry",
        title: "Shoulder",
        subtitle: "Shoulder words",
        order: 27
      };
      break;
    case "score_symmetry_spine_center_xy":
      nameDerived = {     
        domain: "symmetry",  
        // title: isStretchLab ? "Center of Mass" : "Spine Center",
        // subtitle: isStretchLab ? "Center of Mass words" : "Spine Center words",
        title: "Spine Center",
        subtitle: "Spine Center words",
        order: 28
      };
      break;
    case "score_symmetry_spine_center_x_y":
      nameDerived = {    
        domain: "symmetry",   
        // title: isStretchLab ? "Center of Mass" : "Spine Center",
        // subtitle: isStretchLab ? "Center of Mass words" : "Spine Center words",
        title: "Spine Center",
        subtitle: "Spine Center words",
        order: 28
      };
      break;
    case "score_symmetry_hip":
      nameDerived = {   
        domain: "symmetry",    
        title: "Hip",
        subtitle: "Hip words",
        order: 29
      };
      break;
    case "score_symmetry_femur":
      nameDerived = {    
        domain: "symmetry",   
        // title: "Femur",
        // subtitle: "Femur words",
        // title: isStretchLab ? "Femur" : "Squat Depth",
        // subtitle: isStretchLab ? "Femur words" : "Squat Depth words",
        title: "Squat Depth",
        subtitle: "Squat Depth words",
        order: 30
      };
      break;
    case "score_symmetry_knee":
      nameDerived = { 
        domain: "symmetry",      
        title: "Knee",
        subtitle: "Knee words",
        order: 31
      };
      break;
    case "score_symmetry_tibia":
      nameDerived = {  
        domain: "symmetry",     
        // title: isStretchLab ? "Tibia" : "Ankle",
        // subtitle: isStretchLab ? "Tibia words" : "Ankle words",
        title: "Ankle",
        subtitle: "Ankle words",         
        order: 32
      };
      break;
    default:
      nameDerived = {       
        domain: domain, // extracted from name_string
        title: "Bad Name",
        subtitle: scoreObjectNameString,
        order: 0
      };
  }

  nameDerived.subtitle = ""; // TODO: remove & set individual case subtitles instead

  return nameDerived;
}

// { id, dateTime, domain, title, subtitle, score, measurement, trend }
function makeScoresWithTrendsFromAssessment(json, priorJson) {
  let thisAssessment = json;
  let priorAssessment = priorJson;
  let performedDatetime;
  
  if (Array.isArray(json)) {
    thisAssessment = json[0]
  }

  if (Array.isArray(priorJson)) {
    priorAssessment = priorJson[0]
  }

  let allScoresArray = [];

  if (!thisAssessment instanceof Object || thisAssessment === null || typeof thisAssessment === "undefined") {
    // console.log("~~~~~ makeScoresWithTrendsFromAssessment() - thisAssessment is not an object :-(")
    return;
  }

  if (!priorAssessment instanceof Object || priorAssessment === null || typeof priorAssessment === "undefined") {
    // console.log("~~~~~ makeScoresWithTrendsFromAssessment() - priorAssessment is not an object :-(")
    priorAssessment = thisAssessment;
  }

  for (const [key, value] of Object.entries(thisAssessment)) {

    if (key === "performed_datetime") {
      if (typeof value !== "undefined") {
        performedDatetime = new Date(value).toLocaleString();
      }
    }

    if (key === "attribute_hash") {
      var attributes = value;
      
      for (const [key2, value2] of Object.entries(attributes)) {
        
        // for any attribute object key that begins with "score_"
        if (
          key2.split("_")[0] === "score" && 
          // (key2.split("_")[2] !== "elbow" || isStretchLab) && // do not show elbow, even for StretchLab...
          (key2.split("_")[2] !== "elbow") &&
          key2 !== "score_posture_spine_center_yz" &&
          key2 !== "score_posture_spine_center_y_z" 
        ) {
          
          let measurement = null;
          let scapRightIssue = false;
          
          if (key2.split("_").length > 2) { // should skip composite and domain scores
            
            let measurementName = key2.replace('score_', 'measurement_');
            
            if (key2 === "score_stability_scapRight") {
              measurementName = "measurement_stability_scap_right";
              scapRightIssue = true;
            }   
            
            let measurementObj
            
            if (typeof attributes[measurementName] !== "undefined") {
              measurementObj = attributes[measurementName];
            } else {
              console.log("~~~~~ makeScoresFromAssessment - there is no measurement with the name " + measurementName + " for assessment " + thisAssessment.id_guid);
              measurementObj = {
                "created_datetime":"2029-12-08T06:05:31.999Z",
                "updated_datetime":"2029-12-09T04:13:47.999Z",
                "assessment_id_guid":"00000000-ccf0-420f-9e04-b1be884c1699",
                "name_string":"measurement_not_right",
                "value_string":"34.3434343434",
                "unit_string":"double"
              };
            }
            measurement = measurementObj.value_string
          }

          // console.log("~~~~~ makeScoresWithTrendsFromAssessment() - priorAssessment.attribute_hash[key2].name_string:  " + priorAssessment.attribute_hash[key2].name_string);

          let thisScore = {};
          let origScoreObj = value2;
          let priorScoreObj = priorAssessment.attribute_hash[key2];
          // console.log("~~~~~ makeScoresWithTrendsFromAssessment() - priorScoreObj.value_string:  " + priorScoreObj.value_string);
          let nameDerivedObj;

          nameDerivedObj = scapRightIssue ?
            nameDerived('score_stability_scap_right') :
            nameDerived(origScoreObj.name_string);

          let rawScoreString = origScoreObj.value_string;
          let stringScore100 = parseFloat(rawScoreString) < 1.0 ?
            Math.floor(parseFloat(rawScoreString) * 100).toString() :
            rawScoreString;
    
          thisScore.order = nameDerivedObj.order;
          thisScore.domain = nameDerivedObj.domain;
          thisScore.title = nameDerivedObj.title;
          thisScore.subtitle = nameDerivedObj.subtitle;

          // if (!origScoreObj.delta_value_string) {
          //   console.log("~~~~~ there was no delta_value_string");
          // } else {
          //   if (parseInt(origScoreObj.delta_value_string) < 0) {
          //     console.log("~~~~~ there was a negative delta_value_string for " + nameDerivedObj.domain + " " + nameDerivedObj.title + ": " + origScoreObj.delta_value_string);
          //   }
          // }

          // TODO: replace ternary with expanded conditional that also logs FizzyScore_Enum of undefined origScoreObj or value_string
          let origScoreValue_Float = (typeof origScoreObj !== "undefined" && typeof origScoreObj.value_string !== "undefined") ?
            parseFloat(origScoreObj.value_string) : 0.0;

          // TODO: replace ternary with expanded conditional that also logs FizzyScore_Enum of undefined priorScoreObj or value_string
          let priorScoreValue_Float = (typeof priorScoreObj !== "undefined" && typeof priorScoreObj.value_string !== "undefined") ?
            parseFloat(priorScoreObj.value_string) : 0.0;

          let locallyCalculatedDeltaValue = origScoreValue_Float - priorScoreValue_Float;
          // console.log("~~~~~ makeScoresWithTrendsFromAssessment() - locallyCalculatedDeltaValue:  " + locallyCalculatedDeltaValue);
  
          thisScore.id = origScoreObj.id_guid;
          thisScore.dateTime = performedDatetime;
          thisScore.score = stringScore100;
          thisScore.measurement = measurement;
          // thisScore.trend = !origScoreObj.delta_value_string ?
          // "up" : origScoreObj.delta_value_string < 0 ? "dn" : "up";
          thisScore.trend = locallyCalculatedDeltaValue < 0 ? "dn" : "up";

          allScoresArray.push(thisScore);
        }
      }
    }
  }

  // sort scoresArray by order, ascending
  allScoresArray.sort(function(a,b){
    return a.order - b.order;
  });

  return allScoresArray;
}

// { id, dateTime, domain, title, subtitle, score, measurement, trend }
// function makeScoresFromAssessment(assessment, priorAssessment) { // create scores array from one assessment

//   let thisAssessment = assessment;
//   let performedDatetime;
  
//   if (Array.isArray(assessment)) {
//     thisAssessment = assessment[0]
//   }
  
//   let allScoresArray = [];

//   if (!thisAssessment instanceof Object || thisAssessment === null || typeof thisAssessment === "undefined") {
//     console.log("~~~~~ makeScoresFromAssessment() - thisAssessment is not an object :-(")
//     return;
//   }

//   for (const [key, value] of Object.entries(thisAssessment)) {

//     if (key === "performed_datetime") {
//       if (typeof value !== "undefined") {
//         performedDatetime = new Date(value).toLocaleString();
//       }
//     }
    
//     if (key === "attribute_hash") {
//       var attributes = value;
      
//       for (const [key2, value2] of Object.entries(attributes)) {
        
//         // for any attribute object key that begins with "score_"
//         if (
//           key2.split("_")[0] === "score" && 
//           (key2.split("_")[2] !== "elbow" || isStretchLab) &&
//           key2 !== "score_posture_spine_center_yz" &&
//           key2 !== "score_posture_spine_center_y_z" 
//         ) {
          
//           let measurement = null;
//           let scapRightIssue = false;
          
//           if (key2.split("_").length > 2) { // should skip composite and domain scores
            
//             let measurementName = key2.replace('score_', 'measurement_');
            
//             if (key2 === "score_stability_scapRight") {
//               measurementName = "measurement_stability_scap_right";
//               scapRightIssue = true;
//             }   
            
//             let measurementObj
            
//             if (typeof attributes[measurementName] !== "undefined") {
//               measurementObj = attributes[measurementName];
//             } else {
//               console.log("~~~~~ makeScoresFromAssessment - there is no measurement with the name " + measurementName + " for assessment " + thisAssessment.id_guid);
//               measurementObj = {
//                 "created_datetime":"2029-12-08T06:05:31.999Z",
//                 "updated_datetime":"2029-12-09T04:13:47.999Z",
//                 "assessment_id_guid":"00000000-ccf0-420f-9e04-b1be884c1699",
//                 "name_string":"measurement_not_right",
//                 "value_string":"34.3434343434",
//                 "unit_string":"double"
//               };
//             }
//             measurement = measurementObj.value_string
//           }

//           let thisScore = {};
//           let origScoreObj = value2;
//           let nameDerivedObj;

//           nameDerivedObj = scapRightIssue ?
//             nameDerived('score_stability_scap_right') :
//             nameDerived(origScoreObj.name_string);

//           let rawScoreString = origScoreObj.value_string;
//           let stringScore100 = parseFloat(rawScoreString) < 1.0 ?
//             Math.floor(parseFloat(rawScoreString) * 100).toString() :
//             rawScoreString;
    
//           thisScore.order = nameDerivedObj.order;
//           thisScore.domain = nameDerivedObj.domain;
//           thisScore.title = nameDerivedObj.title;
//           thisScore.subtitle = nameDerivedObj.subtitle;

//           if (!origScoreObj.delta_value_string) {
//             console.log("~~~~~ there was no delta_value_string");
//           } else {
//             if (parseInt(origScoreObj.delta_value_string) < 0) {
//               console.log("~~~~~ there was a negative delta_value_string for " + nameDerivedObj.domain + " " + nameDerivedObj.title + ": " + origScoreObj.delta_value_string);
//             }
//           }

//           thisScore.id = origScoreObj.id_guid;
//           thisScore.dateTime = performedDatetime;
//           thisScore.score = stringScore100;
//           thisScore.measurement = measurement;
//           thisScore.trend = !origScoreObj.delta_value_string ?
//           "up" : origScoreObj.delta_value_string > 0 ? "up" : "dn";

//           // thisScore.trend = !priorAssessment ? // TODO: provide priorAssessment !!
//           //   "up" : 
//           //   thisScore.score - priorAssessment.score > 0 ?
//           //     "up" :
//           //     "down";

//           allScoresArray.push(thisScore);
//         }
//       }
//     }
//   }

//   // sort scoresArray by order, ascending
//   allScoresArray.sort(function(a,b){
//     return a.order - b.order;
//   });

//   return allScoresArray;
// }

function makeIssuesFromScores(scoresArray) {

  // if (!Array.isArray(scoresArray) || scoresArray.length === 0) {
  //   return;
  // }
  
  const domainToIgnore = "unknown";
  const sortedScores = scoresArray.slice().sort(function(firstScore, secondScore) { 
    if(firstScore.score > secondScore.score) return 1;
    if(firstScore.score < secondScore.score) return -1;
    return 0;
  });

  const sidedScores = sortedScores.map(score => {
    let side = "";
    const titlePartsArray = score.title.split(' ');

    // what conditions would we need side to NOT be ""
    if (titlePartsArray[0] !== "Spine") {
      if (titlePartsArray.includes("Left")) {
        side = "left";
      } else if (titlePartsArray.includes("Right")) { 
        side = "right";
      } else { // the only scores left are Shoulder level & the symmetry scores
        if (score.measurement > 0) {
          side = "right";
        } else {
          side = "left";
        }
      }
    }
    
    return {
      ...score,
      side: side
    };
  });

  // only make issues for subdomain scores, less than or equal to maxIssueScore 
  return sidedScores.filter((score) => {
    return score.domain !== domainToIgnore && parseInt(score.score, 10) <= maxIssueScore;
  })
}

// function makeIssuesAndScores(assessment) {
//   const scoresData = makeScoresFromAssessment(assessment); // ? missing a parameter?

//   return {
//     "scoresData": scoresData,
//     "issuesData": makeIssuesFromScores(scoresData),
//     "metaData": makeMetaDataFrom(assessment)
//   };
// }

function makeIssuesAndScoresWithTrends(json, priorJson) {
  const scoresData = makeScoresWithTrendsFromAssessment(json, priorJson);

  return {
    "scoresData": scoresData,
    "issuesData": makeIssuesFromScores(scoresData),
    "metaData": makeMetaDataFrom(json)
  };
}

// this will make just the data required for assessment lists
function makeAssessmentsMetaData(respJson) { // takes response to API & returns scanMetasArray
  
  const assessmentMetaDatasArray = [];

  if (respJson && respJson.data && Array.isArray(respJson.data)) {

    const assessmentsArray = respJson.data.filter((assessment) => {
      return assessment.attribute_hash && Object.keys(assessment.attribute_hash).length > 20;
    })

    if (assessmentsArray.length === 0) {
      console.log("~~~~~ makeAssessmentsMetaData() - there were no valid assessment objects")
      return assessmentMetaDatasArray;
    }

    // we need to sort the assessmentsArray by dateTime descending, for display
    // but here they need to be in ascending order, so we always have the prior
    // assessment scores available in the assessmentMetaDatasArray, and we can
    // compare it to the assessment being parsed into the next metaData object
    assessmentsArray.sort(function(a,b){
      return new Date(a.performed_datetime) - new Date(b.performed_datetime);
    });


    // const priorAssessment = {}; // empty object, initially
    
    assessmentsArray.forEach((assessment) => {
      const priorMetaData = assessmentMetaDatasArray.length > 0 ?
      assessmentMetaDatasArray[assessmentMetaDatasArray.length - 1] :
      null;
      let metaData = {
        "id_guid": assessment.id_guid,
        "prior_id_guid": priorMetaData ? priorMetaData.id_guid : assessment.id_guid,
        "memberid": assessment.athlete_user_id_guid,
        // "date": format(Date(assessment.created_datetime), "yyyy/MM/dd"),
        // "time": format(Date(assessment.created_datetime), "kk:mm:ss"),
        // "date": "  " + assessment.performed_datetime.substring(0, 10),
        "date": "  " +  new Date(assessment.performed_datetime).toLocaleDateString(),
        // "time": "  " + assessment.performed_datetime.substring(11,19),
        "time": "  " + new Date(assessment.performed_datetime).toLocaleTimeString(),
        // "time": "  " + new Date(assessment.performed_datetime).toLocaleString().substring(11,19),
        "dateTime": assessment.performed_datetime, // save this to sort by later
        "scoreComposite": 7,
        "scoreComposite_trend": "up",
        "scoreMobility": 7,
        "scoreMobility_trend": "up",
        "scoreStability": 7,
        "scoreStability_trend": "up",
        "scorePosture": 7,
        "scorePosture_trend": "up",
        "scoreSymmetry": 7,
        "scoreSymmetry_trend": "up"
      }

      let topFiveScoresProvided_Int = 0;
      
      if (assessment.attribute_hash) {
        if (assessment.attribute_hash.score_composite) {
          metaData.scoreComposite = score100(assessment.attribute_hash.score_composite);
          topFiveScoresProvided_Int += 1;
          metaData.scoreComposite_trend = priorMetaData ? 
            parseInt(metaData.scoreComposite, 10) - parseInt(priorMetaData.scoreComposite, 10) >= 0 ?
              "up" : "down" :
          "up";
        }
        if (assessment.attribute_hash.score_mobility) {
          metaData.scoreMobility = score100(assessment.attribute_hash.score_mobility);
          topFiveScoresProvided_Int += 1;
          metaData.scoreMobility_trend = priorMetaData ? 
          parseInt(metaData.scoreMobility, 10) - parseInt(priorMetaData.scoreMobility, 10) >= 0 ?
            "up" : "down" :
          "up";
        }
        if (assessment.attribute_hash.score_stability) {
          metaData.scoreStability = score100(assessment.attribute_hash.score_stability);
          topFiveScoresProvided_Int += 1;
          metaData.scoreStability_trend = priorMetaData ? 
          parseInt(metaData.scoreStability, 10) - parseInt(priorMetaData.scoreStability, 10) >= 0 ?
            "up" : "down" :
          "up";
        }
        if (assessment.attribute_hash.score_posture) {
          metaData.scorePosture = score100(assessment.attribute_hash.score_posture);
          topFiveScoresProvided_Int += 1;
          metaData.scorePosture_trend = priorMetaData ? 
          parseInt(metaData.scorePosture, 10) - parseInt(priorMetaData.scorePosture, 10) >= 0 ?
            "up" : "down" :
          "up";
        }
        if (assessment.attribute_hash.score_symmetry) {
          metaData.scoreSymmetry = score100(assessment.attribute_hash.score_symmetry);
          topFiveScoresProvided_Int += 1;
          metaData.scoreSymmetry_trend = priorMetaData ? 
          parseInt(metaData.scoreSymmetry, 10) - parseInt(priorMetaData.scoreSymmetry, 10) >= 0 ?
            "up" : "down" :
          "up";
        }
      }
      if (topFiveScoresProvided_Int === 5) {
        assessmentMetaDatasArray.push(metaData);
      } 
    })
  }

  assessmentMetaDatasArray.sort(function(a,b){
    return new Date(b.dateTime) - new Date(a.dateTime);
  });
  
  return assessmentMetaDatasArray; // we could sort again here, by date desc if required
}
