import React from 'react';
import { Routes, Route } from 'react-router-dom';
// import { propTypes } from 'react-bootstrap/esm/Image';

import Overview from '../Overview';
import Members from '../Members';
import History from '../History';
import Assessment from '../Assessment';

import styled from 'styled-components'

const ContentDiv = styled.div`
  flex: 1;
  padding: 10px;
  background: #ffffff;
  height: 100vh;
  overflow-y: scroll;
`;

function Content(props) { // props: deviceInfo Object { isDesktopOrLaptop, isBigScreen, isTabletOrMobile, isPortrait, isRetina }
  return (
    <ContentDiv>
        <Routes>
          <Route path='/' element={<Overview deviceInfo={props.deviceInfo} />} />
          <Route path='/members' element={<Members deviceInfo={props.deviceInfo} />} />
          <Route path='/history' element={<History deviceInfo={props.deviceInfo} />} />
          <Route path='/assessment' element={<Assessment deviceInfo={props.deviceInfo} />} />
        </Routes>
    </ContentDiv>
  );
}

export default Content;