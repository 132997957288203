import React from 'react';
// import { propTypes } from 'react-bootstrap/esm/Image';

import { storageAvailable } from '../../helpers';

import styled from 'styled-components'
import NavButton from './NavButton';
import { logoSrc } from '../../constants';


const SideBarDiv = styled.div`
  padding: 10px;
  padding-top: 4vh;
  width: 17.7vw;
  min-height: 100vh;
  border-right: 1px solid #e9e9e9; /* colors.lightGreyDividers ? */
`;

const SideBarInnerDiv = styled.div`
  text-align: center;
`;

const SideBarFooterDiv = styled.div`
  height: 7vw;
  width: 15vw;
  position: fixed;
  bottom: 1vw;
  border-top: 1px solid #e9e9e9;
  margin: 0;
  margin-left: 0.8vw;
  /* text-align: center; */
`;

                                                  // { isDesktopOrLaptop, isBigScreen, isTabletOrMobile, isPortrait, isRetina }
function SideBar(props) { // props: logout Function, deviceInfo Object
  const localStorageExists = storageAvailable("localStorage");
  
  function handleLogoClick() {
    // alert("Logo Clicked!")
    if (localStorageExists) {
      console.log("~~~~~ handkeLogoClick() - localStorage Exists!");
      if (localStorage.getItem('theme') === "alternate") {
        console.log("~~~~~ handkeLogoClick() - setting theme to standard");
        localStorage.setItem('theme', '');
        window.location.reload();
      } else {
        console.log("~~~~~ handkeLogoClick() - setting theme to alternate");
        localStorage.setItem('theme', 'alternate');
        window.location.reload();
      }
    }
    else
    {
      console.log("~~~~~ handkeLogoClick() - localStorage does not Exist :-(");
    }
  }
  
  return (
    <SideBarDiv>
      <SideBarInnerDiv>
        <img src={logoSrc} alt="logo" style={{width: "15vw" }} onClick={handleLogoClick} />
        <ul className="navbar-nav mr-auto" style={{ width: "15vw", marginLeft: "auto", marginRight: "auto", marginTop: "6vh", textAlign: "left" }}>
          <li><NavButton linkTo={'/'} icon={"Overview.svg"} title={"Overview"} /></li>
          <li><NavButton linkTo={'/members'} icon={"Members.svg"} title={"Members"} /></li>
        </ul>
      </SideBarInnerDiv>
      <SideBarFooterDiv onClick={props.logout}>
        <NavButton linkTo={'/#'} icon={"Logout.svg"} title={"Logout"} />
      </SideBarFooterDiv>
    </SideBarDiv>
  );
}

export default SideBar;