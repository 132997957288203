// src/constants/themes_and_colors.js

import { storageAvailable } from '../helpers';

const localStorageExists = storageAvailable("localStorage");
/*
const StretchLab_red = "#ee4b54";
const StretchLab_blue = "#003348";
const StretchLab_green = "#00acba";

const StretchLab_logo_src = "sl-logo-full-color.png";
const StretchLab_loading_src = "SL_loading.svg";
*/
const Physmodo_purple = "#516efe";
const Physmodo_blue = "#3c9bff";

let Physmodo_accent_color = Physmodo_purple;
let Physmodo_logo_src = "Physmodo_logo.svg";
let Physmodo_loading_src = "Physmodo_loading_1.svg";

if (localStorageExists && localStorage.getItem('theme') === "alternate") {
  Physmodo_accent_color = Physmodo_blue;
  Physmodo_logo_src = "Physmodo_logo_2.png";
  Physmodo_loading_src = "Physmodo_loading_2.svg";
}

const accentColor = Physmodo_accent_color;
const logoSrc = Physmodo_logo_src;
const loadingSrc = Physmodo_loading_src;

const lightGrey = "#e9e9e9";

const colors = {
  background: "#ffffff",
  lightGreyDividers: lightGrey,
  blackText: "#000000",
  greyText: "#7a7c8d", // TODO: this is not really a grey shade...
  lightGreyText: "#b4b4b4",
  scoreGreen: "#7cea62",
  scoreYellow: "#f4f436",
  scoreRed: "#f2a2a"
}

export {
  accentColor,
  logoSrc,
  loadingSrc,
  colors
}

