// services/auth.js
// 3 methods hit API: loginUser(), forgotPassword(), checkUserAuth()

import { storageAvailable } from '../helpers';

function logout() {
  localStorage.setItem('alreadyReloaded', false);
  // if (localStorageExists) {
  if(storageAvailable('localStorage')){ 
    localStorage.setItem('token', null);
    localStorage.setItem('alreadyReloaded', false);
    window.location.reload();
  } else {
    // setToken(null);
    localStorage.setItem('token', null);
  }
}

async function loginUser(credentials) {
  try {
    console.log("~~~~~ loginUser() ~~~~~");
    const response = await fetch('https://data.physmodo.net/api/v202203/user/authentication/jwt', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })

    console.log("~~~~~ loginUser() - after");

    const bodyMsg = response && response.body ? "response body exists" : "no body";
    const statusMsg = response && (response.status === 200 || response.status === 201) ? "response status 200/201" : "bad response status - " + response.status;
    
    console.log("~~~~~ loginUser() - response.body: " + bodyMsg);
    console.log("~~~~~ loginUser() - response.status: " + statusMsg);

    // localStorage.setItem('loggedInUser', 'fdbc3b28-1980-434f-80ff-c5d6d8377eec');

    let authFailObject = {
      token: "authFail",
      user: {name: "authFail", email: "authFail", id_guid: "ffffffff-1111-4444-8888-cccccccccccc"}
    }
    
    if (response && response.headers.get('authorization')) {
      return {
        token: response.headers.get('authorization'),
        user: await response.json()
      }
    } else {
      return authFailObject
    }
 
  }
  catch (e) {
    console.log(e)
  }
}

// TODO: implement this with try/catch for network errors that prevent fetch from returning anything
async function networkError(invokedMethod_string, error) {
  alert('networkError() - ' + invokedMethod_string + ' method returned a ' + error.name + ' network error');
  console.log('networkError() - ' + invokedMethod_string + ' method returned Error Code ' + error.name + ' network error');
  let errorText = 'nothing yet';
  /*
  if (error.status === '401') {
    errorText = 'The ' + invokedMethod_string + ' method returned a ' + statusCode + ' error from the server';
    checkUserAuth(invokedMethod_string);
  }
  else
  {
    errorText = 'The ' + invokedMethod_string + ' method returned a ' + statusCode + ' error from the server';
    localStarage.setItem('errorToast', errorText);
  }
  */
  alert(errorText);
}

// this function will be invoked inside a conditional (response.ok===false) in the try block of the try/catch for service methods
// that query the server.  It will conditionally invoke different actions based on the 
// error that was returned (and the method that was invoked and returned the error?).

// Initially, the only two actions will be checkUserAuth() for 401 errors, and 
// responseErrorToast() for all others.  

// responseErrorToast() will be a shell method (or App.js for original Stretchlab dashboard?) that will be invoked
// when a useEffect() in Shell() detects a string with length > 0 in localStorage.getItem('errorToast');
function responseError(invokedMethod_string, response) {
  const httpError = response.status;
  //alert('responseError() - ' + invokedMethod_string + ' method returned a ' + httpError + ' error');
  console.log('responseError() - ' + invokedMethod_string + ' method returned Error Code ' + httpError + ' error');
  let errorText = 'nothing yet';
  
  if (httpError === 401) { // NOTE: httpError is and INTEGER, not a STRING !!
    errorText = 'responseError() - The ' + invokedMethod_string + ' method returned an Unauthorized Error (401) from the server';
    checkUserAuth(invokedMethod_string);
  }
  else
  {
    errorText = 'responseError() - The ' + invokedMethod_string + ' method returned a ' + httpError + ' error from the server';
    localStorage.setItem('errorToast', errorText); // TODO: have useEffect() in Shell() check localStorage('errorText')
    // in Stretchlab dashboard (this one!), where will we check and respond to localStorage('errorText')?
    // - will we have to do it in every View? Overview, Members, Assessment, History? or in Sidebar?, or App.js? 
  }
  
  // alert(errorText);
  console.log(errorText);
}

// This function will query the server to get currently valid user token (or null), and will update the token
// stored in localStorage('token'). There will be a new method tokenCheck() in Shell() that will check the value in
// localStorage for 'token' every time the Shell is reloaded, and display the Sign-In screen if appropriate.
function checkUserAuth(invokedMethod_string) {
    // alert('checkUserAuth()');
    console.log('checkUserAuth()');
  // try {
    // const response = await fetch('https://data.physmodo.net/api/v202203/app/user/authentication', {
    return fetch('https://data.physmodo.net/api/v202203/app/user/authentication', {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      }

    })
    .then(authResp => {
      const resp = authResp;
      if (!resp.ok) {
        //alert('checkUserAuth() - method ' + invokedMethod_string + ' checkUserAuth failed with resp.ok === false and resp.status = ' + resp.status);
        console.log('checkUserAuth() - method ' + invokedMethod_string + ' checkUserAuth failed with resp.ok === false and resp.status = ' + resp.status);
        // localStorage.setItem('alreadyReloaded', 'false');
      }
      else
      {
        const token = authResp.headers.get('authorization');
        //alert('checkUserAuth() - method ' + invokedMethod_string + ' checkUserAuth returned this token: ' + token);
        console.log('checkUserAuth() - method ' + invokedMethod_string + ' checkUserAuth returned this token: ' + token);
      }
      
      localStorage.setItem('token', authResp.headers.get('authorization')); // set token in storage to value returned
    })
    // localStorage.setItem('token', response.headers.get('authorization'));
    // alert(invokedMethod_string + ' returned a 401 error');
    //return response.headers.get('authorization');
  // }
  // catch (e) {
  //   console.log(e)
  // }
}

async function forgotPassword(email) {
  try {
    const response = await fetch('https://data.physmodo.net/api/v202203/user/authentication/forgot', {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      // body: JSON.stringify(credentials)
      body: JSON.stringify(email)
    })
    return response.headers.get('authorization');
  }
  catch (e) {
    console.log(e)
  }
}

export {
  logout,
  loginUser,
  networkError,
  responseError,
  checkUserAuth,
  forgotPassword
}