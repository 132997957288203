import React from 'react';
import styled from 'styled-components';
import { accentColor } from '../../constants';


const Title = styled.h2`
  margin: 0;
  padding: 0;
  padding-left: 1vw;
  font-size: 1.3vw;
  color: #000000;
`

const BackLink = styled.h4`
  color: ${accentColor};
  font-size: 1.2vw;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`

const ContentWrapper = styled.div`
  background: white;
  padding-left: 3vw;
  padding-top: 3vw;
  display: none;
  ${ props => props.loaded 
    ? '{ display: block }'
    : '{ display: none }'
  } 
`

function EZSpacer(props) {
  let spacerHeight = props.height ? props.height : "10px";
  let spacerWidth = props.width ? props.width : "100%";

  return (
    <div style={{
      margin: 0,
      padding: 0,
      width: spacerWidth,
      height: spacerHeight
    }} />
  );
}



// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
  background: papayawhip;
`;

const PrettyInput = styled.input`
  background: #f7f7f7;
  width: 25vw;
  min-width: 100px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  border: none;
  border:solid 1px #ccc;
  border-radius: 1.0vw;
  margin-top: -10px;
  margin-bottom: 20px;
  font-size: 1.5vw;
`;

const PrettyInputLabel = styled.label`
  text-align: left;
  font-size: 1.0em;
  padding: 0;
  margin: 0;
  color: #7a7c8d;
`;

const LoginButton = styled.button`
  background: ${accentColor};
  width: 25vw;
  min-width: 100px;
  color: #ffffff;
  padding: 12px;
  border: none;
  border:solid 1px #ccc;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 50px;
  font-size: 1.0em;
`

const ForgotPasswordLink = styled.a`
  font-size: 1.1vw;
  text-align: center;
  color: ${accentColor};
  display: block;
  margin-top: 15px;
`;

const LoginForm = styled.form`
  text-align: center;
`;

const TabButton = styled.button`
  display: inline-block;
  min-width: 7.6vw;
  min-height: 2.0vw;
  color: ${accentColor}; 
  background: ${accentColor};  
  margin: 0.76vw 1.9vw 0.15vw 0vw !important; /* top right bottom left */
  padding: 0.4vw 0.75vw 0.4vw 0.75vw !important; /* top right bottom left */  
  border: none;
  border-radius: 0.45vw; /* was 10px */
  ${ props => props.tabTitle === props.currentTab 
    ? '{ color: #ffffff; background: ' + accentColor + '; }'
    : '{ color: ' + accentColor + '; background: #ffffff; }'
  } 
  font-size: 1.0vw;
  font-weight: 500;
  letter-spacing: 0.15vw;
`;


export { 
  Title, 
  Wrapper, 
  PrettyInput, 
  PrettyInputLabel, 
  ForgotPasswordLink,
  LoginForm,
  LoginButton,
  EZSpacer,
  BackLink,
  ContentWrapper,
  TabButton
};
