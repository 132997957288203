import React from 'react';
import styled from 'styled-components';

const minYellowScore = 30; // eventually inport something like { minYellowScore } from './config'
const maxYellowScore = 89; // eventually inport something like { maxYellowScore } from './config'

const bodyMapWidthInVw = 11;
const bodyMapHeightInVw = bodyMapWidthInVw * 1506 / 722;

const BodyMapDiv = styled.div`
  position: relative;
  background-image: url("bodymap.svg");
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
  width: calc(1vw * ${bodyMapWidthInVw});
  height: calc(1vw * ${bodyMapHeightInVw});
  margin-left: 4.5vw;
`;

const MarkerDiv = styled.div`
  position: absolute; 
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0 !important;
  /* border: 1px solid #888888; */
`;

const StyledSVG = styled.svg`
  position: absolute; 
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0 !important;
`;


function markerData(issue) {
  // console.log("~~~~~ issueOffsets() - issue.title: " + issue.title);
  
  const issueArea = issue.title.split(' ')[0];
  // console.log("~~~~~ issueOffsets() - issueArea: " + issueArea);
  
  let offset;

  switch(issueArea) {
    case "Elbow":
      offset = { x: issue.side === "right" ? 0.27 : -0.27, y: -0.13 };
      break;
    case "Shoulder":
    case "Scap":
      offset = { x: issue.side === "right" ? 0.20 : -0.20, y: -0.28 };
      break;
    case "Spine":
    case "Center":
      offset = { x: 0, y: -0.14 };
      break;
    case "Hip":
      offset = { x: issue.side === "right" ? 0.12 : -0.12, y: -0.01 };
      break;
    case "Femur":
      offset = { x: issue.side === "right" ? 0.12 : -0.12, y: 0.12 };
      break;
    case "Knee":
    case "Valgus/Varus":
      offset = { x: issue.side === "right" ? 0.135 : -0.135, y: 0.22 };
      break;
    case "Ankle":
    case "Tibia":
      offset = { x: issue.side === "right" ? 0.11 : -0.12, y: 0.43 };
      break;
    default:
      offset = { x: 0.0, y: 0.0 };
  }

  return {
    x_offset: offset.x,
    y_offset: offset.y,
    color: issue.score > maxYellowScore ? "green" : issue.score < minYellowScore ? "red" : "yellow",
    type: "circle" // when we have more, we'll set them in the switch(issueArea) statement
  }
}

function IssueMarker(props) {
  const {
    markerData,
    size
  } = props;
  const strokeWidth = size / 10;
  // const center = size / 2;
  const radius = size / 2.5 - strokeWidth / 2.5;
  const backgroundStroke = "#ffffff";

  const redStroke = "#ff2957";
  const yellowStroke = "#f4f53d";
  const greenStroke = "#7dea62";
  
  let circleStroke = redStroke; // default stroke color is red

  if (markerData.color && markerData.color === "yellow") {
    circleStroke = yellowStroke;
  } else if (markerData.color && markerData.color === "green") {
    circleStroke = greenStroke;
  }

  if (markerData.type === "circle") { // returns a circle centered on the provided offsets from center
    const cx = (0.5 + markerData.x_offset) * 100 + "%";
    const cy = (0.5 + markerData.y_offset) * 100 + "%";
    
    return (
      <MarkerDiv>
        <StyledSVG className="svg">
          <circle
            className="svg-circle"
            stroke={backgroundStroke}
            cx={cx}
            cy={cy}
            r={radius / 2}
            strokeWidth={radius}
            opacity={0.4}
          />
          <circle
            className="svg-circle"
            stroke={circleStroke}
            cx={cx}
            cy={cy}
            r={radius}
            strokeWidth={strokeWidth}
          />
        </StyledSVG> 
      </MarkerDiv>
    )  
  }
}


function BodyMap(props) {
  const {                      
    data,
    domain,
    maxIssues
  } = props;       

  const allIssues = data.filter((issue) => {
    return issue.domain === domain;
  });

  const displayIssues = allIssues.slice(0, maxIssues); // just issues we show (the worst)
  
  return (
    <BodyMapDiv>
        {displayIssues.map((issue) => {
          return (
            <IssueMarker key={issue.id}
              markerData={markerData(issue)}
              size={"33"}
            />
          )
        })}
      {/* <IssueMarker 
        markerData={testMarkerData}
        size={"33"}
      /> */}
    </BodyMapDiv>
  );
}

export default BodyMap;